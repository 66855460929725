import React from 'react';
import {useTranslation} from "react-i18next";
import Menu from "../../../components/Menu/Menu";
import Button from "../../../components/Button/Button";
import Arrow from "../../../assets/img/js/Arrow";
import {scrollTo} from "../../../tools/scroll";
import eagleSvg from "../../../assets/img/svg/eagle.svg";
import './Main.scss';

function Main() {
    const {t} = useTranslation();

    return (
        <div className='home-main'>
            <Menu className='home-main-menu'/>
            <div className='home-main-content'>
                <p className='home-main-content-title uppercase'>
                    {t('home.title')}
                    <img src={eagleSvg} className='home-main-content-title-eagle' alt='eagle'/>
                </p>
                <p className='home-main-content-subtitle'>{t('home.make')}</p>
                <p className='home-main-content-subtitle uppercase'>{t('home.efficient')}</p>
                <Button onClick={() => scrollTo('#plans-anchor')}>
                    <p className='home-main-content-how'>{t('seeHow')}</p>
                    <Arrow />
                </Button>
            </div>
        </div>
    );
}

export default Main;
