import React from 'react';
import {useTranslation} from "react-i18next";
import './WhatWeDo.scss';

function WhatWeDo() {
    const {t} = useTranslation();

    return (
        <div className='what-we-do'>
            <p className='what-we-do-title'>{t('home.what.title')}</p>
            <p className='what-we-do-text'>{t('home.what.offer')}</p>
            <p className='what-we-do-text'>{t('home.what.identify')}</p>
            <p className='what-we-do-text'>{t('home.what.help')}</p>
            <p className='what-we-do-text'>{t('home.what.experience')}</p>
        </div>
    );
}

export default WhatWeDo;
