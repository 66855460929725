import React from 'react';
import './Button.scss';

function Button({className, children, onClick = () => {}}) {
    return (
        <div className={`button ${className || ''}`} onClick={onClick}>
            {children}
        </div>
    );
}

export default Button;
