import React from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button/Button";
import Arrow from "../../../assets/img/js/Arrow";
import {scrollTo} from "../../../tools/scroll";
import "./Join.scss";

function Join() {
    const {t} = useTranslation();
    return (
        <div className='join'>
            <div className='join-content'>
                <p className='join-content-text'>{t('home.join.work')}</p>
                <p className='join-content-text'>{t('home.join.think')}</p>
                <Button className='join-content-button' onClick={() => scrollTo('#plans-anchor')}>
                    <p className='join-content-button-text'>
                        {t('seeHow')}
                    </p>
                    <Arrow color={'#000250'}/>
                </Button>
            </div>
        </div>
    );
}

export default Join;
