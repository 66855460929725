import React from 'react';
import {useTranslation} from "react-i18next";
import relationshipPng from "../../../assets/img/png/relationship.png";
import "./Relationship.scss";

function Relationship() {
    const {t} = useTranslation()
    return (
        <div className='relationship'>
            <div className="relationship-content">
                <div className="relationship-content-text">
                    <p className='relationship-content-text-title'>{t('home.relationship.title')}</p>
                    <p className='relationship-content-text-info'>{t('home.relationship.text')}</p>
                </div>
                <div className="relationship-content-img">
                    <img src={relationshipPng} alt='relationship'/>
                </div>
            </div>
            <div className='relationship-cut'>
                <div className='relationship-cut-white'>

                </div>
            </div>
        </div>
    );
}

export default Relationship;
