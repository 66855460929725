import React from 'react';
import './ReasonWhy.scss';

function ReasonWhy({className, img, title, text}) {
    return (
        <div className={`reason-why ${className || ''}`}>
            <img src={img} alt={title} className='reason-why-img'/>
            <p className='reason-why-title'>{title}</p>
            <p className="reason-why-text">{text}</p>
        </div>
    );
}

export default ReasonWhy;
