import React from 'react';
import {useTranslation} from "react-i18next";
import Logo from "../Logo/Logo";
import Link from "../Link/Link";
import {CONTACTS_ROUTE, SERVICES_ROUTE, HOME_ROUTE} from "../Router/links";
import "./Footer.scss"

function Footer({className}) {
    const {t} = useTranslation()

    return (
        <div className={`footer ${className || ''}`}>
            <div className='footer-content'>
                <div className='footer-content-links'>
                    <Link route={HOME_ROUTE} className='footer-content-links-link' text={t('link.home')} />
                    <Link route={SERVICES_ROUTE} className='footer-content-links-link' text={t('link.services')} />
                    <Link route={CONTACTS_ROUTE} className='footer-content-links-link' text={t('link.contact')} />
                </div>
                <div className='footer-content-logo'>
                    <Logo />
                </div>
                <div className='footer-content-copyright'>
                    <p className='footer-content-copyright-text'>
                        ©2023 VULTURE COMMERCE. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
