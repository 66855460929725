import React from 'react';
import logoSvg from '../../assets/img/svg/logo.svg';

function Logo({className}) {
    return (
        <div className={`logo ${className || ''}`}>
            <img src={logoSvg} className='logo-img' alt='logo'/>
        </div>
    );
}

export default Logo;
