import React from 'react';
import './Plan.scss';

function Plan({className, img, title, text}) {
    return (
        <div className={`plan ${className || ''}`}>
            <img className='plan-img' src={img} alt={`img_${title}`}/>
            <p className='plan-title'>{title}</p>
            <p className='plan-text'>{text}</p>
        </div>
    );
}

export default Plan;
