import React from 'react';

function Arrow({color = 'white'}) {
    return (
        <svg width="7" height="13" fill='none' viewBox="0 0 7 13" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1.1665L6.33333 6.49984L1 11.8332"
                strokeWidth="1.33333"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Arrow;
