import {BrowserRouter} from "react-router-dom";
import Router from "./components/Router/Router";
import './index.scss';

function App() {
    return (
        <BrowserRouter>
            <div className="app">
                <Router />
            </div>
        </BrowserRouter>
    );
}

export default App;
