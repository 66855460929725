import React from 'react';
import {useTranslation} from "react-i18next";
import Plan from "./Plan/Plan";
import businessSvg from "../../../assets/img/svg/plans/business.svg";
import helpSvg from "../../../assets/img/svg/plans/help.svg";
import investSvg from "../../../assets/img/svg/plans/invest.svg";
import marketingSvg from "../../../assets/img/svg/plans/marketing.svg";
import summarySvg from "../../../assets/img/svg/plans/summary.svg";
import strategySvg from "../../../assets/img/svg/plans/strategy.svg";
import './Plans.scss';

function Plans() {
    const {t} = useTranslation();

    return (
        <div className='plans' id='plans-anchor'>
            <div className='plans-content'>
                <Plan
                    className='plans-content-plan'
                    img={businessSvg}
                    title={t('home.plans.business.title')}
                    text={t('home.plans.business.text')}
                />
                <Plan
                    className='plans-content-plan'
                    img={summarySvg}
                    title={t('home.plans.summary.title')}
                    text={t('home.plans.summary.text')}
                />
                <Plan
                    className='plans-content-plan'
                    img={helpSvg}
                    title={t('home.plans.help.title')}
                    text={t('home.plans.help.text')}
                />
                <Plan
                    className='plans-content-plan'
                    img={marketingSvg}
                    title={t('home.plans.marketing.title')}
                    text={t('home.plans.marketing.text')}
                />
                <Plan
                    className='plans-content-plan'
                    img={investSvg}
                    title={t('home.plans.invest.title')}
                    text={t('home.plans.invest.text')}
                />
                <Plan
                    className='plans-content-plan'
                    img={strategySvg}
                    title={t('home.plans.strategy.title')}
                    text={t('home.plans.strategy.text')}
                />
            </div>
        </div>
    );
}

export default Plans;
