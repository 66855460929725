import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Logo from "../Logo/Logo";
import Link from "../Link/Link";
import {HOME_ROUTE, SERVICES_ROUTE, CONTACTS_ROUTE} from "../Router/links";
import burgerSvg from "../../assets/img/svg/burger.svg";
import closeSvg from "../../assets/img/svg/close.svg";
import './Menu.scss'

function Menu({className}) {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`menu ${className || ''} ${isOpen && 'menu-open'}`}>
            <div className='menu-content'>
                <Logo />
                <div className='menu-content-links'>
                    <Link route={HOME_ROUTE} className='menu-content-links-link' text={t('link.home')} />
                    <Link route={SERVICES_ROUTE} className='menu-content-links-link' text={t('link.services')} />
                    <Link route={CONTACTS_ROUTE} className='menu-content-links-link' text={t('link.contact')} />
                </div>
                <div className='menu-content-mobile'>
                    <img
                        className='menu-content-mobile-img'
                        onClick={() => setIsOpen(!isOpen)}
                        src={isOpen ? closeSvg : burgerSvg}
                        alt='menu-action'
                    />
                </div>
            </div>
            <div className={`menu-mobile_links ${!isOpen && 'menu-mobile_links-closed'}`}>
                <Link
                    click={() => setIsOpen(false)}
                    route={HOME_ROUTE}
                    className='menu-mobile_links-link'
                    text={t('link.home')}
                />
                <Link
                    click={() => setIsOpen(false)}
                    route={SERVICES_ROUTE}
                    className='menu-mobile_links-link'
                    text={t('link.services')}
                />
                <Link
                    click={() => setIsOpen(false)}
                    route={CONTACTS_ROUTE}
                    className='menu-mobile_links-link'
                    text={t('link.contact')}
                />
            </div>
        </div>
    );
}

export default Menu;
