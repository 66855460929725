import React, {Suspense} from 'react';
import Home from "../../pages/Home/Home";
import {Routes, Route} from "react-router-dom";
import {publicRoutes} from "./routes";

function AppRouter() {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    {publicRoutes.map(({path, Component}) =>
                        <Route key={path} path={path} element={Component}/>
                    )}
                    <Route path='*' element={<Home />} exact/>
                </Routes>
            </Suspense>
        </>
    );
}

export default AppRouter
