import React from "react";
import {HOME_ROUTE, CONTACTS_ROUTE, SERVICES_ROUTE} from "./links";

const Home = React.lazy(() => import("../../pages/Home/Home")
    .then(({default : Home}) => ({default: Home})));
const Contacts = React.lazy(() => import("../../pages/Contacts/Contacts")
    .then(({default : Contacts}) => ({default: Contacts})));
const Services = React.lazy(() => import("../../pages/Services/Services")
    .then(({default : Services}) => ({default: Services})));


const addRoute = (path, component) => {
    return {
        path: path,
        Component: component,
    }
}

export const publicRoutes = [
    addRoute(HOME_ROUTE, <Home/>),
    addRoute(CONTACTS_ROUTE, <Contacts/>),
    addRoute(SERVICES_ROUTE, <Services/>),
]
