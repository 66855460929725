import React from 'react';
import Main from "./Main/Main";
import WhatWeDo from "./WhatWeDo/WhatWeDo";
import Plans from "./Plans/Plans";
import Relationship from "./Relationship/Relationship";
import WhyUs from "./WhyUs/WhyUs";
import Join from "./Join/Join";
import Footer from "../../components/Footer/Footer";
import './Home.scss';

function Home() {
    return (
        <div className='home'>
            <Main />
            <WhatWeDo />
            <Plans />
            <Relationship />
            <WhyUs />
            <Join />
            <Footer />
        </div>
    );
}

export default Home;
