import React from 'react';
import {useTranslation} from "react-i18next";
import ReasonWhy from "./ReasonWhy/ReasonWhy";
import experiencePng from "../../../assets/img/png/reasonsWhy/experience.png";
import kingPng from "../../../assets/img/png/reasonsWhy/king.png";
import transparencyPng from "../../../assets/img/png/reasonsWhy/transparency.png";
import "./WhyUs.scss";

function WhyUs() {
    const {t} = useTranslation();
    return (
        <div className='why-us'>
            <div className="why-us-title">{t('home.whyUs.title')}</div>
            <div className='why-us-content'>
                <ReasonWhy
                    img={kingPng}
                    title={t('home.whyUs.client.title')}
                    text={t('home.whyUs.client.text')}
                />
                <ReasonWhy
                    img={transparencyPng}
                    title={t('home.whyUs.transparency.title')}
                    text={t('home.whyUs.transparency.text')}
                />
                <ReasonWhy
                    img={experiencePng}
                    title={t('home.whyUs.experience.title')}
                    text={t('home.whyUs.experience.text')}
                />
            </div>
            <div className='why-us-cut'>
                <div className='why-us-cut-blue'>{}</div>
                <div className='why-us-cut-white'>{}</div>
            </div>
        </div>
    );
}

export default WhyUs;
