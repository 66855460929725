import React from 'react';
import {NavLink} from "react-router-dom";
import {HOME_ROUTE} from "../Router/links";
import './Link.scss'

function Link({className, text, route= HOME_ROUTE, click = () => {}}) {
    return (
        <NavLink onClick={() => click()}  to={route} className={`link ${className || ''}`}>
            {text}
        </NavLink>
    );
}

export default Link;
